.App {
    text-align: center;
}

.App .top {
    display: flex;
    justify-content: space-between;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.App .top .total {
    font-size: 1.7em;
    font-weight: bold;
}

.App .top button.reset,
.App .top button.save {
    margin-left: 0.7em;
    margin-right: 0.7em;
    font-size: 1em;
}

.App .top button.save {
    visibility: hidden;
}

.App .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
}

.App table {
    width: auto;
    min-width: 300px;
    border: none;
    margin-top: 1em;
}

.App table tr {
    width: auto;
    border: none;
}

.App table tr td {
    width: auto;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.5em;
    border: none;
    text-align: start;
    font-size: 0.8em;
}

.App table tr td ~ td {
    text-align: end;
}

.App table tr td button {
    min-width: 2em;
    font-size: 1em;
    font-weight: bolder;
}

.App table tr td button ~ button {
    margin-left: 0.5em;
}

.App .categories,
.App .items {
    width: 100%;
    max-width: 550px;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.App .categories button,
.App .items button {
    width: 45%;
    font-size: 1em;
    margin: 5px;
}

.App .categories button span,
.App .items button span {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

.App footer {
    display: none;
    margin-top: 2em;
    color: rgba(0, 0, 0, 0.2);
}