body {
    background-color: #F0FFF0;
    color: #000000;
    margin: 0;
    font-family: myriad-pro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #003399;
}
